import axios from 'axios'
import handleAlerts from './handleAlerts'

export default function dynamicLink() {
  const { successfulOperationAlert, failedOperationAlert } = handleAlerts()

  const generateDynamicLink = (id, route, params = {}) => axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.VUE_APP_FIREBASE_TOKEN}`, {
    dynamicLinkInfo: {
      domainUriPrefix: 'https://muslimdo.page.link',
      link: `https://muslimdo.page.link?route=${route}&id=${id}&object=${JSON.stringify(params)}`,
      androidInfo: {
        androidPackageName: process.env.VUE_APP_ANDROID_PACKAGE_NAME,
      },
      iosInfo: {
        iosBundleId: '1555298494',
      },
      socialMetaTagInfo: {
        socialTitle: params.title,
        socialDescription: params.description,
        socialImageLink: params.image,
      },
    },
  }).then(res => {
    const link = res.data.shortLink

    navigator.clipboard.writeText(link).then(() => {
      successfulOperationAlert('Dynamic link is copied successfully')
    }).catch(() => {
      failedOperationAlert('Failed to generate dynamic link')
    })

    return link
  })

  return { generateDynamicLink }
}
