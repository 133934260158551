<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-NotificationTokenController"
        action="sendToListOfUsers"
        add-route-name="add-notifications"
      />
    </div>

    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Title / Body"
            label-for="search"
          >
            <search
              placeholder="Title / Body"
              :search-query.sync="searchQuery.keyword"
              @refresh="refreshTable"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <custom-vue-select
            id="sent-status"
            label="Sent Status"
            placeholder="Sent Status"
            :value-reducer="option=>option.value"
            :options="[
              {text:'All',value:null},
              {text:'Yes',value:true},
              {text:'No',value:false},
            ]"
            :selected.sync="searchQuery.sent"
            text-field="text"
          />
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Date"
            label-for="date-range-filter"
          >
            <date-range-filter :date-range.sync="searchQuery.dateRange" />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-row align-h="end">
        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <spinner-loader :loading="loading" />

    <b-card>
      <b-table
        ref="table"
        :items="getNotifications"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :fields="tableColumns"
        show-empty
        responsive
        empty-text="No notifications found"
      >
        <template #cell(is_sent)="{item}">
          {{ item.is_sent ? 'Yes' : 'No' }}
        </template>
        <template
          #cell(actions)="{item}"
        >
          <table-actions
            v-if="!item.is_sent"
            v-bind="{
              id:item.chunk,
              deleteEndPoint,
              deleteRecord,
            }"
          />
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import TableActions from '@/common/components/common/Table/TableActions.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import paginationData from '@/common/compositions/common/paginationData'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'

export default {
  name: 'NotificationsList',
  components: {
    AddResource,
    TableActions,
    Search,
    SearchButton,
    Pagination,
    SpinnerLoader,
    CustomVueSelect,
    DateRangeFilter,
  },
  data() {
    return {
      searchQuery: { dateRange: [] },
      tableColumns: [
        { key: 'title' },
        { key: 'body' },
        { key: 'sends_at', label: 'Sent At' },
        { key: 'is_sent', label: 'Sent' },
        { key: 'actions' },
      ],
      loading: true,
      deleteEndPoint: '/internalops/admin-notification-delete',
    }
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return { successfulOperationAlert, confirmOperation, pagination }
  },
  methods: {
    getNotifications() {
      const promise = this.$portalUsers.get('internalops/admin-notification-list', {
        params: {
          ...this.searchQuery,
          fromDate: this.searchQuery.dateRange[0],
          toDate: this.searchQuery.dateRange[1],
          target: '',
          page: this.pagination.currentPage,
        },
      })

      return promise.then(res => {
        const notifications = res.data.data

        this.pagination.totalRows = res.data.pagination?.total
        return notifications || []
      }).finally(() => {
        this.loading = false
      })
    },
    deleteRecord(deleteEndPoint, chunk) {
      this.confirmOperation().then(() => {
        this.$portalUsers.post(deleteEndPoint, { chunk },
          {
            headers: {
              entityId: this.$store.getters['mainEntity/getEntityId'],
            },
          }).then(() => {
          this.successfulOperationAlert('Notification is deleted successully')
          this.$refs.table.refresh()
        })
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">

</style>
