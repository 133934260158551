<template>
  <b-dropdown-item @click="generateDynamicLink(id,route,additionalParams)">
    <feather-icon icon="CopyIcon" />
    <span> Copy Link </span>
  </b-dropdown-item>
</template>
<script>
import dynamicLink from '@/common/compositions/common/createDynamicLink'

export default {
  name: 'ShareDeepLink',
  props: {
    id: { type: Number, default: null },
    route: { type: String, default: null },
    additionalParams: { type: Object, default: () => {} },
  },
  setup() {
    const { generateDynamicLink } = dynamicLink()
    return { generateDynamicLink }
  },
}
</script>
<style lang="">

</style>
