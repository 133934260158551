var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right mb-1"},[_c('add-resource',{attrs:{"resource-name":"global-NotificationTokenController","action":"sendToListOfUsers","add-route-name":"add-notifications"}})],1),_c('b-card',{attrs:{"header":"Search"}},[_c('b-form-row',[_c('b-col',{staticClass:"mb-1 mb-md-0",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Title / Body","label-for":"search"}},[_c('search',{attrs:{"placeholder":"Title / Body","search-query":_vm.searchQuery.keyword},on:{"update:searchQuery":function($event){return _vm.$set(_vm.searchQuery, "keyword", $event)},"update:search-query":function($event){return _vm.$set(_vm.searchQuery, "keyword", $event)},"refresh":_vm.refreshTable}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('custom-vue-select',{attrs:{"id":"sent-status","label":"Sent Status","placeholder":"Sent Status","value-reducer":function (option){ return option.value; },"options":[
            {text:'All',value:null},
            {text:'Yes',value:true},
            {text:'No',value:false} ],"selected":_vm.searchQuery.sent,"text-field":"text"},on:{"update:selected":function($event){return _vm.$set(_vm.searchQuery, "sent", $event)}}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date","label-for":"date-range-filter"}},[_c('date-range-filter',{attrs:{"date-range":_vm.searchQuery.dateRange},on:{"update:dateRange":function($event){return _vm.$set(_vm.searchQuery, "dateRange", $event)},"update:date-range":function($event){return _vm.$set(_vm.searchQuery, "dateRange", $event)}}})],1)],1)],1),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"md":"2"}},[_c('search-button',{on:{"refresh":_vm.refreshTable}})],1)],1)],1),_c('spinner-loader',{attrs:{"loading":_vm.loading}}),_c('b-card',[_c('b-table',{ref:"table",attrs:{"items":_vm.getNotifications,"current-page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"fields":_vm.tableColumns,"show-empty":"","responsive":"","empty-text":"No notifications found"},scopedSlots:_vm._u([{key:"cell(is_sent)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_sent ? 'Yes' : 'No')+" ")]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [(!item.is_sent)?_c('table-actions',_vm._b({},'table-actions',{
            id:item.chunk,
            deleteEndPoint: _vm.deleteEndPoint,
            deleteRecord: _vm.deleteRecord,
          },false)):_vm._e()]}}])})],1),_c('pagination',{attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }